import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Pretendard from "../typo/Pretendard";
import Header from "../pure/layout/Header";
import { withRouter, RouteComponentProps } from "react-router-dom";
// import BackgroundImage from "../assets/images/about.png";
import { getTopPadding } from "../pure/layout/LayoutStyle";
import Clients from "../pure/Clients";
import Footer from "../pure/layout/Footer";
import Colors from "../pure/Colors";
import { ArrowForwardRounded } from "@mui/icons-material";

type HomeScreenProps = {
} & RouteComponentProps<{}>;

type HomeScreenState = {
    WindowWidth: number;
    WindowHeight: number;
    buttonHover: boolean;
}

class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
    constructor(props: HomeScreenProps) {
        super(props);
        this.state = {
            WindowHeight: window.innerHeight,
            WindowWidth: window.innerWidth,
            buttonHover:false,
        }
    }

    setWindowResize (w: number, h: number) {
        if (this.state.WindowHeight !== h || this.state.WindowWidth !== w) {
            this.setState({WindowHeight: h, WindowWidth: w});
            return;
        };
        return;
    }

    componentDidMount() {
        
        window.scrollTo(0, 0);
        window.addEventListener("resize", () => {
            this.setWindowResize(window.innerWidth, window.innerHeight);
            return;
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.setWindowResize(window.innerWidth, window.innerHeight)}, true);
    }

    getBlockHeight () {
        if(this.state.WindowWidth < 970) return 250;
        else return 400;
    }

    getLogoStyle () {
        if (this.state.WindowWidth <= 570) return {width: 80, height: 64, margin: 8};
        else if (this.state.WindowWidth <= 970) return {width: 160, height: 128, margin: 10};
        else return { width: 250, height: 200, margin: 15}
    }

    getVideoSize () {
        // video rate is 16:9
        const currWidth = this.state.WindowWidth;
        const idealHeight = currWidth / 16 * 9 ;
        if (this.state.WindowHeight > idealHeight) {
            const videoWidth = this.state.WindowHeight / 9 * 16;
            const videoHeight = this.state.WindowHeight;
            return { videoHeight, videoWidth };
        }
        return { videoHeight : idealHeight, videoWidth : currWidth};
    }  

    getProcessItemAlign (index: number) {
        const currWidth = this.state.WindowWidth;
        if (currWidth <= 960) {
            return "center"
        } else {
            if(index === 0) return "flex-end";
            else if (index === 1) return "center";
            else return "flex-start"
        }
    }

    getAlignItems (index: number) {
        const currWidth = this.state.WindowWidth;
        if(currWidth < 900) {
            return "center"
        } else {
            if(index % 2 === 0) return "flex-end";
            else return "flex-start"
        }
    }

    getAlignText (index: number) {
        const currWidth = this.state.WindowWidth;
        if(currWidth < 900) {
            return "center"
        } else {
            if(index % 2 === 0) return "right";
            else return "left"
        }
    }

    getImageSize () {
        const currWidth = this.state.WindowWidth;
        if (currWidth < 570) {
            return 200;
        } else if(currWidth < 900) {
            return 400;
        } else {
            return 600;
        }
    }

    gerTextMarginSize () {
        const currWidth = this.state.WindowWidth;
         if(currWidth < 900) {
            return 0;
        } else {
            return 60;
        }
    }
 
    render() {
        const ImageSize = this.getImageSize();
        const textMarginSize = this.gerTextMarginSize();
        const borderRadius = this.state.WindowWidth < 900 ? 400 : 0;

        return(
            <Grid container item xs={12}>
               <Header 
                WindowWidth={this.state.WindowWidth} 
                backgroundMode={"transparent"} 
                onHistoryChanged={(path) => {this.props.history.push(path)}}
                curr="about"/>
                
                <Grid container item xs={12} style={{width: this.state.WindowWidth, height: this.getVideoSize().videoHeight}}>
                     <iframe 
                    style={{
                        zIndex: -1,
                        position: "fixed", 
                        border: `0px solid white`, 
                        width: this.getVideoSize().videoWidth, 
                        height: this.getVideoSize().videoHeight, 
                        objectFit:"cover", padding: 0, backgroundColor: Colors.pallete1_blue}}
                    src="https://player.vimeo.com/video/564472612?autoplay=1&amp;loop=1&amp;background=1&amp;title=0&amp;byline=0&amp;portrait=0"
                    width={this.state.WindowWidth}
                    height={"100%"}
                    allowFullScreen
                
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture">
                </iframe>
                <Grid container item xs={12} style={{zIndex: 1, width:"100%", backgroundColor: `#00000080`}}>
                <Container>
                        <Grid container item xs={12}  style={{paddingTop: getTopPadding(this.state.WindowWidth) + 100, paddingBottom: getTopPadding(this.state.WindowWidth)}}>

                        <Grid container item xs={12}>

                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{lineHeight: 1.2, color: "white"}}>
                                <span style={{fontFamily:"Pretendard-Regular"}}>당신의 메시지를 
                                <br />
                                정확하고 임팩트 있게 전달하는,
                                <br /></span>
                                841 PRODUCTION
                            </Pretendard> 
                        </AnimationOnScroll> 
                        
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: this.state.WindowWidth > 570 ? 60 : 30}}>

                            <AnimationOnScroll animateOnce={true}  animateIn="animate__slideInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="caption" style={{color:"white"}}>
                               841 PRODUCTION은 <span style={{backgroundColor:Colors.pallete1_yellow, color: Colors.pallete1_blue, fontFamily:"Pretendard-Bold"}}>
                               클라이언트의 메세지를 정확하고 임팩트 있게 전달하는 것</span>을 첫번째 목표로 추구합니다. <br />
                               기획, 제작, 후반 작업까지 모든 프로세스를 소화할 수 있는 인하우스 시스템을 통해 <br />
                               광고, 유투브 컨텐츠, 뮤직 비디오 등 다양한 형태의 컨텐츠를 제작합니다. <br />
                               841 PRODUCTION만의 노하우를 통해 최고의 효율로 최상의 결과물을 구현하며,
                               빠른 피드백을 바탕으로 클라이언트가 원하는 모든 장르의 영상을 원하는 느낌으로 구현합니다. <br />
                            </Pretendard>
                            </AnimationOnScroll>
                            
                        </Grid>


                        </Grid>
                        
                    </Container>
                </Grid>
                <Grid container item xs={12} style={{width: this.state.WindowWidth,  backgroundColor: `#00000080`, }}>
                    <Container>
                        <Grid container item xs={12} style={{width:"100%", borderTop: "1px solid white", paddingTop: getTopPadding(this.state.WindowWidth) / 2 , paddingBottom: getTopPadding(this.state.WindowWidth) / 2}}>
                        <Grid container item xs={12} md={6}>
                        <Pretendard windowWidth={this.state.WindowWidth} type="caption" style={{color:"white"}}>
                                841 PRODUCTION에 대해 더 궁금하신 점이 있다면,<br />
                                우측의 회사 소개서를 확인하여 자세한 내용을 확인해보세요
                            </Pretendard>
                        </Grid>
                        <Grid container item xs={12} md={6} justifyContent="flex-end">
                            <Button 
                                onClick={() => {
                                    window.open("http://drive.google.com/uc?export=view&id=1H-e4c2zNv1clVRTe2zNmXJ-opjBz7c6d", "_blank");
                                    return;
                                }}
                                onMouseEnter={() => {this.setState({buttonHover: true})}}
                                onMouseLeave={() => {this.setState({buttonHover: false})}}
                                style={{paddingLeft:24, paddingRight: 24,  height: 56, borderRadius: 10, border: "1px solid white", backgroundColor: this.state.buttonHover === true ? "white" : "transparent"}}>
                            <Pretendard windowWidth={this.state.WindowWidth} type="caption" style={{color: this.state.buttonHover === true ? "black" : "white", fontFamily: "Pretendard-Bold"}}>
                                회사소개서 보기
                            </Pretendard>
                            </Button>
                        </Grid>
                        </Grid>
                        
                    </Container>
                </Grid>
                <Grid container item xs={12} style={{width: this.state.WindowWidth, paddingTop: getTopPadding(this.state.WindowWidth), paddingBottom: getTopPadding(this.state.WindowWidth), backgroundColor:Colors.pallete1_blue}}>
              

                    <Container>
                    <Grid container item xs={12} justifyContent="center" style={{marginBottom: 20}}>
                             <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{color: "white", textAlign:"center"}}>
                                Production Process
                            </Pretendard>
                        </AnimationOnScroll>
                        
                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            
                        <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: "white", textAlign:"center"}}>
                                841 Production은 기획부터 후반 작업까지 모든 프로세스를 소화합니다.
                                <br />
                                841 Production은 클라이언트의 메세지를 가장 효과적으로 전달할 수 있는 최상의 결과물이 나올 수 있도록 도와드립니다.
                            </Pretendard>
                        </AnimationOnScroll>
                        </Grid>
                        </Container>
                        
                    <Grid container item xs={12} style={{marginTop: 100, width:"100%"}}>
                        <Container>
                        <Grid container item xs={12} direction="row-reverse" style={{width:"100%", marginBottom: 40}}>
                        <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(0)} style={{width:"100%"}}>
                                <div style={{position:"relative", width: ImageSize, height: ImageSize, overflow:'hidden', borderRadius }}>
                                <iframe style={{
                        width: ImageSize / 9 * 16, 
                        height: ImageSize,
                        left: -(ImageSize/9*16/4),
                        position:"absolute", 
                        zIndex: 0,
                        objectFit:"cover", 
                        opacity: 0.9,
                        padding: 0, backgroundColor: Colors.pallete1_blue}}
                    src="https://player.vimeo.com/video/568060416?autoplay=1&amp;loop=1&amp;background=1&amp;title=0&amp;byline=0&amp;portrait=0"
                    allowFullScreen
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture" />
                                </div>
                                
                            </Grid>
                            <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(0)} alignItems={"center"} style={{width:"100%"}}>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{color: Colors.pallete1_yellow, textAlign:this.getAlignText(0), marginRight: -textMarginSize, zIndex: 1}}>
                                CREATIVE
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: Colors.pallete1_yellow, textAlign:this.getAlignText(0), marginRight: -textMarginSize, zIndex: 1}}>
                            클라이언트의 needs를 충족시킬<br/>프로덕션841의 신선하고 놀라운 기획안
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                            </Grid>
                            
                        </Grid>


                        <Grid container item xs={12} direction="row" style={{width:"100%", marginBottom: 40}}>
                        <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(1)} style={{width:"100%"}}>
                                <div style={{position:"relative", width: ImageSize, height: ImageSize, overflow:'hidden', borderRadius }}>
                                <iframe style={{
                       width: ImageSize / 9 * 16, 
                       height: ImageSize,
                       left: -(ImageSize/9*16/4),
                        position:"absolute", 
                        opacity: 0.9,
                        zIndex: 0,
                        objectFit:"cover", padding: 0, backgroundColor: Colors.pallete1_blue}}
                    src="https://player.vimeo.com/video/525088349?autoplay=1&amp;loop=1&amp;background=1&amp;title=0&amp;byline=0&amp;portrait=0"
                    allowFullScreen
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture" />
                                </div>
                                
                            </Grid>
                            <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(1)} alignItems={"center"} style={{width:"100%"}}>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{color: Colors.pallete1_yellow, textAlign:this.getAlignText(1), marginLeft: -textMarginSize, zIndex: 1}}>
                            PRODUCTION
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: Colors.pallete1_yellow, textAlign:this.getAlignText(1), marginLeft: -textMarginSize, zIndex: 1}}>
                            촬영, 조명, vfx supervisor 까지<br />각 분야의 전문가들과 협업하여 최고의 촬영 결과물 도출
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                            </Grid>
                            
                        </Grid>

                        <Grid container item xs={12} direction="row-reverse" style={{width:"100%", marginBottom: 40}}>
                        <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(2)} style={{width:"100%"}}>
                                <div style={{position:"relative", width: ImageSize, height: ImageSize, overflow:'hidden', borderRadius }}>
                                <iframe style={{
                        width: ImageSize /   3 * 5 / 9 * 16 , 
                        height: ImageSize / 3 * 5,
                        position:"absolute", 
                        zIndex: 0,
                        opacity: 0.9,
                        top: -(ImageSize / 3 ),
                        left: -(ImageSize /   3 * 5 / 9 * 16 / 2),
                        objectFit:"cover", padding: 0, backgroundColor: Colors.pallete1_blue}}
                    src="https://player.vimeo.com/video/525402302?autoplay=1&amp;loop=1&amp;background=1&amp;title=0&amp;byline=0&amp;portrait=0"
                    allowFullScreen
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture" />
                                </div>
                                
                            </Grid>
                            <Grid container item xs={12} md={6} justifyContent={this.getAlignItems(2)} alignItems={"center"} style={{width:"100%"}}>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{lineHeight: 1.2,  color: Colors.pallete1_yellow, textAlign:this.getAlignText(2), marginRight: -textMarginSize, zIndex: 1}}>
                            POST<br />PRODUCTION
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: Colors.pallete1_yellow, textAlign:this.getAlignText(2), marginRight: -textMarginSize, zIndex: 1}}>
                            편집, 2D, 3D vfx, 음악, 믹싱,<br/>디아이, 컬러그레이딩 one-stop system
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                            </Grid>
                            <Grid container item xs={12} style={{marginTop: 20}}>
                             <AnimationOnScroll animateIn="animate__fadeInLeft">
                                 <Button onClick={() => { this.props.history.push("/works")}}>
                                 <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: "white"}}>
                               VIEW MORE OUR WORKS
                            </Pretendard>
                            <ArrowForwardRounded style={{color: "white"}} />
                                 </Button>
                            
                        </AnimationOnScroll>
                        </Grid>
                        </Grid>

                        
                        </Container>
                        {/* <Grid container item xs={12} md={4} alignItems="center" justifyContent={"center"} style={{paddingLeft: 15, paddingRight: 15, position:"relative", marginBottom: 10}}>
                        <img src={require("../assets/images/abouts/abouts1.png")} style={{width: this.getBlockHeight(), borderRadius: this.getBlockHeight(),  height:  this.getBlockHeight(), objectFit:"cover"}} />
                            <Grid container item xs={12} justifyContent={"center"} alignItems={"center"} style={{position:"absolute", zIndex: 1}}>
                             <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{color: "white", textAlign:"center"}}>
                                CREATIVE
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: Colors.pallete1_yellow, textAlign:"center"}}>
                            클라이언트의 needs를 충족시킬<br/>프로덕션841의 신선하고 놀라운 기획안
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                        </Grid>
                        </Grid> */}

                        {/* <Grid container item xs={12} md={4} alignItems="center" justifyContent={"center"} style={{paddingLeft: 15, paddingRight: 15, position:"relative", marginBottom: 10}}>
                        <img src={require("../assets/images/abouts/abouts2.png")} style={{width: this.getBlockHeight(), borderRadius: this.getBlockHeight(),  height:  this.getBlockHeight(), objectFit:"cover"}} />
                            <Grid container item xs={12} alignItems="center" justifyContent={"center"} style={{position:"absolute", zIndex: 1}}>
                             <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{color: "white", textAlign:"center"}}>
                            PRODUCTION
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: Colors.pallete1_yellow, textAlign:"center"}}>
                            촬영, 조명, vfx supervisor 까지<br />각 분야의 전문가들과 협업하여 최고의 촬영 결과물 도출
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                        </Grid>
                        </Grid> */}

                        
                        {/* <Grid container item xs={12} md={4} alignItems="center" justifyContent={"center"} style={{paddingLeft: 15, paddingRight: 15, position:"relative", marginBottom: 10}}>
                        <img src={require("../assets/images/abouts/abouts3.png")} style={{width: this.getBlockHeight(), borderRadius: this.getBlockHeight(),  height:  this.getBlockHeight(), objectFit:"cover"}} />
                            <Grid container item xs={12} alignItems="center" justifyContent={"center"} style={{position:"absolute", zIndex: 1}}>
                             <AnimationOnScroll animateOnce={true}  animateIn="animate__zoomIn">
                             <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{color: "white", textAlign:"center"}}>
                                POST PRODUCTION
                            </Pretendard>
                            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color:Colors.pallete1_yellow, textAlign:"center"}}>
                            편집, 2D, 3D vfx, 음악, 믹싱,<br/>디아이, 컬러그레이딩 one-stop system
                            </Pretendard>
                        </AnimationOnScroll>
                        </AnimationOnScroll>
                        </Grid>
                        </Grid> */}

                        </Grid>
                   
                </Grid>
                <Grid container item xs={12} style={{width: this.state.WindowWidth, paddingTop: 150, paddingBottom: 150, backgroundColor: `#00000080`}}>
                <Container>
                    <Grid container item xs={12}>
                    <Grid container item xs={12}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{lineHeight: 1.2, color:"white"}}>
                                OUR CLIENTS
                            </Pretendard>
                        </AnimationOnScroll>
                        </Grid>
                        <Grid container item xs={12}>
                        <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{lineHeight: 1.2, color:"white"}}>
                                841 PRODUCTION은 다양한 클라이언트와 함께 작업하고 있습니다.
                            </Pretendard>
                        </AnimationOnScroll>
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: 60}}>
                                {Clients.map((cli, index) => {
                                    return(
                                        <AnimationOnScroll animateOnce={true}  delay={(index % 4 * 150)} animateIn="animate__zoomIn">
                                             <img src={require(`../assets/images/clients/${cli}.png`)} style={{...this.getLogoStyle(), objectFit:"contain"}} />
                                            </AnimationOnScroll>
                                    )
                                })}
                           
                           
                            
                        </Grid>
                   </Grid>
                    </Container>
                </Grid>
                <Footer WindowWidth={this.state.WindowWidth} onHistoryChanged={(url) => { this.props.history.push(url)}}/>

                </Grid>
            </Grid>
        )
    }
}

export default withRouter(HomeScreen);