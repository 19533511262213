import React from "react";
// import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Images, { ImageSource } from "./PreviewImages";
import SlideImage from "./images/SlideImage";
import Pretendard from "../typo/Pretendard";
// import {TransitionGroup} from "react-transition-group";

var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    rows:1,
    
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


type ImageCarouselProps = {
    windowWidth: number
}

type ImageCarouselState = {
    direction: string,
}

class ImageCarousel extends React.PureComponent<ImageCarouselProps, ImageCarouselState> {
    constructor(props: ImageCarouselProps) {
        super(props);
        this.state = {
            direction: ""
        }
    }

    renderSlideImage (image: ImageSource) {
        return(
           <SlideImage key={image.title} image={image} />
            
        )
      }


    render() {
        return(
            <div style={{width:"100%"}}>
        <Slider dots={this.props.windowWidth <= 960 ? false : true} {...settings} autoplay autoplaySpeed={3000}>
          {Images.map((image) => this.renderSlideImage(image))}
        </Slider>
      </div>
        )
    }
}

export default ImageCarousel;