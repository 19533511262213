import { Container, Grid, IconButton } from "@mui/material";
import React from "react";
import Pretendard from "../../typo/Pretendard";
import Colors from "../Colors";

type FooterProps = {
    WindowWidth: number,
    onHistoryChanged: (path: string) => void,
}

type FooterState = {
    
}

class Footer extends React.PureComponent<FooterProps, FooterState> {
    constructor(props: FooterProps) {
        super(props);
        this.state = {
            
        }
    }


    getIconSize () {
        if(this.props.WindowWidth <= 570) return 40;
        else return 50;
    }

    alignFooter () {
        if(this.props.WindowWidth <= 570) return "center";
        else return undefined;
    }

    alignIcons () {
        if(this.props.WindowWidth <= 570) return "center";
        else return "flex-end";
    }


    render() {
       return(
        <Grid container item style={{width: "100%", backgroundColor: Colors.pallete1_blue, paddingTop: 60, paddingBottom: 60}}>
            <Container>
                <Grid container item xs={12} style={{width:"100%"}}>
                <Grid container item xs={12} md={8} justifyContent={this.alignFooter()}>
                    <Grid container item xs={12} style={{marginBottom: 10}}justifyContent={this.alignFooter()}>
                    <img src={require("../../assets/images/logo_white.png")} style={{width: 200, height: 100, objectFit:"contain"}} />

                    </Grid>
                    
                    <Grid container item xs={12} style={{marginTop: 12}}justifyContent={this.alignFooter()}>
                    <Pretendard windowWidth={this.props.WindowWidth} type="caption2" style={{color:"white"}}>
                    서울특별시 종로구 종로5길 13 삼공빌딩 3층
                    </Pretendard>
                    </Grid>
                    <Grid container item xs={12}justifyContent={this.alignFooter()}>
                    <Pretendard windowWidth={this.props.WindowWidth} type="caption2" style={{color:"white"}}>
                    13 Jong-ro gil Jongno-gu Seoul Republic of Korea
                    </Pretendard>
                    </Grid>
                    <Grid container item xs={12}justifyContent={this.alignFooter()}>
                    <Pretendard windowWidth={this.props.WindowWidth} type="caption2" style={{color:"white"}}>
                    0507-1411-6988 | 841prod@naver.com
                    </Pretendard>
                    </Grid>
                    <Grid container item xs={12} style={{marginTop: 20}}justifyContent={this.alignFooter()}>
                    <Pretendard windowWidth={this.props.WindowWidth} type="caption2" style={{color:"white", fontFamily: "Pretendard-Thin"}}>
                    ⓒPRODUCTION841 All Right Reserved.
                    </Pretendard>
                    </Grid>
                    

                </Grid>
                <Grid container item xs={12} md={4} justifyContent={this.alignIcons()}>
     <IconButton onClick={() => {window.open("https://www.instagram.com/production_841/", "__blank")}} style={{width: this.getIconSize(), height: this.getIconSize(), margin: 6}}>
         <img src={require("../../assets/images/icons/insta.png")} style={{width: this.getIconSize(), height: this.getIconSize(), borderRadius: 30, objectFit:"contain"}}/>
     </IconButton>
     <IconButton onClick={() => {window.open("https://www.youtube.com/channel/UCOn61h1rryD6Tp4G8tnkNKQ", "__blank")}} style={{width: this.getIconSize(), height: this.getIconSize(), margin: 6}}>
         <img src={require("../../assets/images/icons/youtube.png")} style={{width: this.getIconSize(), height: this.getIconSize(), borderRadius: 30, objectFit:"contain"}}/>
     </IconButton>
    </Grid>
                </Grid>
            </Container>
        
    </Grid>
       )
    }
}

export default Footer;