import React from "react";
import {Button } from "@mui/material";
import Pretendard from "../../typo/Pretendard";
import Colors from "../Colors";
type TagButtonProps = {
    WindowWidth: number,
    title: string,
    onClick: () => void,
    isSelected: boolean
};

type TagButtonState = {
    hover: boolean,
}

class TagButton extends React.PureComponent<TagButtonProps, TagButtonState> {
    static defaultProps = {
        title: "",
        onClick: () => {},
        isSelected: false
    }
    constructor(props: TagButtonProps) {
        super(props);
        this.state= {
            hover: false
        }
    }

    buttonStyle () {
        if (this.props.isSelected === true ) {
            if (this.state.hover === true) {
                return { backgroundColor: Colors.pallete1_yellow,  color: Colors.pallete1_blue, opacity: 0.8};
            } else {
                return {backgroundColor: "white", color: Colors.pallete1_blue,}
            }
        }
        if (this.state.hover === true) {
            return { backgroundColor: Colors.pallete1_yellow, color: Colors.pallete1_blue, opacity: 0.8};
        } else {
            return {backgroundColor: "transparent", color: "white"}
        }
    }

    renderPaddnigStyle () {
        const { WindowWidth } = this.props;
        if(WindowWidth <= 570) return {paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, borderRadius: 30, marginRight: 8, marginBottom: 8};
        else return { paddingTop: 8, paddingBottom: 8, paddingLeft: 16, paddingRight: 16, marginRight: 12, borderRadius: 30,}
    }

    render() {
        return(
            <Button 
                onMouseLeave={() => {this.setState({hover: false})}}
                onMouseEnter={() => {this.setState({hover: true})}}
                style={{...this.renderPaddnigStyle(), border: `1.5px solid white`, ...this.buttonStyle()}}
                onClick={() => {this.props.onClick()}}>
                <Pretendard windowWidth={this.props.WindowWidth} type="button">
                    {this.props.title}
                </Pretendard>
            </Button>
        )
    }
}

export default TagButton