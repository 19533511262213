import { IconButton, ImageListItem } from "@mui/material";
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Pretendard from "../../typo/Pretendard";
import { convertCategory, ImageSource } from "../PreviewImages";
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
type WorkImageProps = {
    image: ImageSource,
    WindowWidth: number,
    onClick: (image: ImageSource) => void;
}

type WorkImageState = {
    hover: boolean
}

class WorkImage extends React.PureComponent<WorkImageProps, WorkImageState> {
    static defaultProps = {
        onClick: () => {}
    }
    constructor(props: WorkImageProps) {
        super(props);
        this.state = {
            hover: false
        }
    }

    render() {
        const { image } = this.props;
        return(
            <AnimationOnScroll animateOnce={true}  animateIn={"animate__fadeIn"}>
                                <ImageListItem
                                     onMouseEnter={() => { this.setState({hover: true})}}
                                     onMouseLeave={() => { this.setState({hover: false})}}
                                     onClick={() => { this.props.onClick(image)}}
                                     key={image.title} 
                                     style={{margin: 0, padding: 0, overflow:"hidden", cursor:"pointer", position:"relative"}}>
                                    <img src={image.source} loading="lazy" style={{
                                        zIndex: -1, 
                                        objectFit:"cover",
                                        transform: this.state.hover === true ? "scale(1.2)" : undefined,
                                        transition: ".6s"
                                        }}/>
                                     {this.state.hover !== true && this.props.WindowWidth <= 570 && <div style={{position:"absolute", zIndex: 999, top: "5%", left: "3%"}}>
                                        <Pretendard windowWidth={this.props.WindowWidth} type="body"  style={{color: "white", fontFamily:"Pretendard-Bold"}}>
                                            {image.title}
                                        </Pretendard>
                                        <br />
                                        <Pretendard windowWidth={this.props.WindowWidth} type="button"  style={{color: "white"}}>
                                            {image.company}
                                        </Pretendard>
                                        
                                    </div>} 
                                    {this.state.hover === true && <div style={{
                                        position:"absolute",
                                        backgroundColor:"#00000070",
                                        top: 0, left: 0, display:"flex", alignItems: "center", justifyContent:"center", width: "100%", height: "100%", zIndex: 999}}>
                                            <div style={{position:"absolute", zIndex: 999, top: "5%", left: "3%"}}>
                                        <Pretendard windowWidth={this.props.WindowWidth} type="body"  style={{color: "white", fontFamily:"Pretendard-Bold"}}>
                                            {image.title}
                                        </Pretendard>
                                        <br />
                                        <Pretendard windowWidth={this.props.WindowWidth} type="button"  style={{color: "white"}}>
                                            {image.company}
                                        </Pretendard>
                                        
                                    </div>
                                        <AnimationOnScroll animateIn="animate__bounceIn">
                                            <IconButton style={{ width: 80, height: 80}}>
                                                <PlayCircleFilledRoundedIcon style={{fontSize: 80, color: "white"}} />
                                            </IconButton>
                                            <Pretendard windowWidth={this.props.WindowWidth} type="button" style={{color: "white", textAlign:"center", marginTop: 10}}>
                                                Play now!
                                            </Pretendard>
                                        </AnimationOnScroll>
                                        </div>}
                                    {/* {this.state.hover !== true && <div style={{position:"absolute", zIndex: 999, bottom: "5%", left: "3%"}}>
                                        {image.category.map((category) => (<div style={{padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 30, border: `1px solid white`}}>
                                        <Pretendard windowWidth={this.props.WindowWidth} type="caption2"  style={{color: "white", fontFamily:"Pretendard-Bold"}}>
                                            #{convertCategory(category)}
                                        </Pretendard>
                                       
                                        </div>))}
                                        
                                        
                                    </div>} */}
                                    
                                </ImageListItem>
                                </AnimationOnScroll>
        )
    }
}

export default WorkImage;