import React from "react";
import { Grid, Container, Button } from "@mui/material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Pretendard from "../typo/Pretendard";
import Carousel from "../pure/ImageCarousel2";
// import TagButton from "../pure/buttons/TagButton";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Header from "../pure/layout/Header";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getTopPadding } from "../pure/layout/LayoutStyle";
import Footer from "../pure/layout/Footer";
import Images from "../pure/PreviewImages";
import Colors from "../pure/Colors";
import Clients from "../pure/Clients";

type HomeScreenProps = {

} & RouteComponentProps<{}>;

type HomeScreenState = {
    WindowWidth: number;
    WindowHeight: number;
    headerMode: "transparent" | "black",
    category? : "viral" | "edu" | "portfolio" | "film",
    showWorksIndex: number,
    prevIndex?: number,
}

class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
    constructor(props: HomeScreenProps) {
        super(props);
        this.state = {
            WindowHeight: window.innerHeight,
            WindowWidth: window.innerWidth,
            headerMode: "transparent",
            category: undefined,
            showWorksIndex: 0,
            prevIndex: undefined,
        }
    }

    setWindowResize (w: number, h: number) {
        if (this.state.WindowHeight !== h || this.state.WindowWidth !== w) {
            this.setState({WindowHeight: h, WindowWidth: w});
            return;
        };
        return;
    }

    catchHeaderModeChange() {
        if(window.scrollY >= this.getVideoSize().height && this.state.headerMode === "transparent") this.setState({headerMode: "black"});
        if (window.scrollY < this.getVideoSize().height && this.state.headerMode === "black") this.setState({headerMode: "transparent"}); 
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("resize", () => {
            this.setWindowResize(window.innerWidth, window.innerHeight);
            return;
        });
        window.addEventListener("scroll", () => {
            this.catchHeaderModeChange();
            return;
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.setWindowResize(window.innerWidth, window.innerHeight)}, true);
        window.removeEventListener("scroll", () => { this.catchHeaderModeChange() }, true);
    }

    getVideoSize () {
        // video rate is 16:9
        
        const currWidth = this.state.WindowWidth;
        const idealHeight = currWidth / 16 * 9 ;
        if(idealHeight < this.state.WindowHeight) {
            const idealWidth = this.state.WindowHeight / 9 * 16;
            return { width: idealWidth, height: this.state.WindowHeight, left: -((idealWidth - this.state.WindowWidth)/2) }
        }
        return {height: idealHeight, width: this.state.WindowWidth };
    }

    filteredImages () {
    
        if(this.state.category === undefined) return Images;
        const targetCategory = this.state.category;
        const filtered = Images.filter((image) => {
            if(image.category.includes(targetCategory)) return true;
            else return false;
        });
        return filtered;
    }


    getAnimationIn () {
        if(this.state.prevIndex !== undefined) {
            const totalIndex = Images.length;
            const half = Math.floor(totalIndex / 2);
            const offset = this.state.prevIndex - this.state.showWorksIndex;
            if(offset > 0 && offset > half) return "animate__slideInRight";
            else if (offset > 0 && offset <= half) return "animate__slideInLeft";
            else if (offset <= 0 && offset <= -half) return "animate__slideInLeft";
            else return "animate__slideInRight";
        }
        return "animate__slideInRight";
    }
   

    renderWorkImageBackground () {
        
        return(
            <>
            {this.state.prevIndex !== undefined&&  <img src={Images[this.state.prevIndex].source} style={{position:"absolute", top: -4, left: -4, width: this.state.WindowWidth, height: "100%", filter:"blur(8px)"}}/>}
            {this.state.prevIndex !== undefined && (<AnimationOnScroll 
                afterAnimatedIn={() => {this.setState({prevIndex: undefined})}} 
                animateIn={this.getAnimationIn()}
                style={{position:"absolute", top: -4, left: -4, width:"100%", height: "100%"}}>
                <img src={Images[this.state.showWorksIndex].source} style={{width: this.state.WindowWidth, height: "100%", filter:"blur(8px)"}}/>
            </AnimationOnScroll>)}
            {this.state.prevIndex === undefined && <img src={Images[this.state.showWorksIndex].source} style={{position:"absolute", top: -4, left: -4, width: this.state.WindowWidth, height: "100%", filter:"blur(8px)"}}/>}
            <div style={{backgroundColor: Colors.pallete1_blue, opacity: 0.7, position:'absolute', top: 0, left: 0, width:"100%", height:"100%"}} />
            </>
        )
    }

    getLogoStyle () {
        if (this.state.WindowWidth <= 570) return {width: 80, height: 64, margin: 8};
        else if (this.state.WindowWidth <= 970) return {width: 160, height: 128, margin: 10};
        else return { width: 250, height: 200, margin: 15}
    }
 
    render() {
        const renderImages = this.filteredImages();
        const yellowCircleSize = this.state.WindowWidth <= 570 ? 300 : 600
        return(
            <Grid container item xs={12} style={{width:this.state.WindowWidth, overflowX: "hidden"}}>
               <Header 
               WindowWidth={this.state.WindowWidth} 
               backgroundMode={this.state.headerMode} curr="home"
               onHistoryChanged={(path) => {this.props.history.push(path)}}
               />
                {/* VIDEO HOME */}
                <Grid container item xs={12} style={{ width: "100%", height: this.getVideoSize().height}}>

                <iframe 
                style={{zIndex: -1,position: "fixed",  border: `0px solid white`, ...this.getVideoSize(), objectFit:"cover", padding: 0, backgroundColor: "black"}}
                src="https://player.vimeo.com/video/566097348?autoplay=1&amp;loop=1&amp;background=1&amp;title=0&amp;byline=0&amp;portrait=0"
                width={this.state.WindowWidth}
                height={"100%"}
                allowFullScreen
               
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture">
            </iframe>
                </Grid>
                <Grid container item xs={12} style={{width: this.state.WindowWidth, overflowX: "hidden", backgroundColor:"white", paddingTop: getTopPadding(this.state.WindowWidth), paddingBottom: getTopPadding(this.state.WindowWidth)}}>
                    <Container>
                        <Grid container item xs={12} style={{position:"relative", }}>
                            <div style={{zIndex: 1, width: yellowCircleSize, height: yellowCircleSize, position:"absolute", top: -(yellowCircleSize/250*80), left: -(yellowCircleSize/400*200)}}>

                            <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn" style={{}}>
                            <div style={{borderRadius: yellowCircleSize,  width: yellowCircleSize, height: yellowCircleSize, backgroundColor: Colors.pallete1_yellow, }} />

                            </AnimationOnScroll>
                            </div>
                        <Grid container item xs={12} style={{zIndex: 2}}>

                        <AnimationOnScroll  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{lineHeight: 1.2}}>
                                WORKS
                            </Pretendard>
                        </AnimationOnScroll>
                        
                        </Grid>
                        <Grid container item xs={12} style={{zIndex: 2}}>
                             <AnimationOnScroll animateIn="animate__fadeInLeft">
                                 <Button onClick={() => { this.props.history.push("/works")}}>
                                 <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{color: "black"}}>
                                SEE MORE
                            </Pretendard>
                            <ArrowForwardRoundedIcon style={{color: "black"}} />
                                 </Button>
                            
                        </AnimationOnScroll>
                        </Grid>
                        </Grid>
                        
                    </Container>
                     <Grid container item xs={12} style={{marginTop: 20, zIndex: 2}}>
                            <Carousel windowWidth={this.state.WindowWidth} />
                           
                        </Grid>
                </Grid>
                <Grid container item xs={12} style={{position:"relative", overflow:"hidden", width: this.state.WindowWidth, paddingTop: getTopPadding(this.state.WindowWidth), paddingBottom: getTopPadding(this.state.WindowWidth), backgroundColor: `#00000080`}}>
                   {/* {this.renderWorkImageBackground()} */}
                   <Container>
                    <Grid container item xs={12}>
                    <Grid container item xs={12}>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{lineHeight: 1.2, color:"white"}}>
                                OUR CLIENTS
                            </Pretendard>
                        </AnimationOnScroll>
                        </Grid>
                        <Grid container item xs={12}>
                        <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="button" style={{lineHeight: 1.2, color:"white"}}>
                                841 PRODUCTION은 다양한 클라이언트와 함께 작업하고 있습니다.
                            </Pretendard>
                        </AnimationOnScroll>
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: 60}}>
                                {Clients.map((cli, index) => {
                                    return(
                                        <AnimationOnScroll animateOnce={true}  delay={(index % 4 * 150)} animateIn="animate__zoomIn">
                                             <img src={require(`../assets/images/clients/${cli}.png`)} style={{...this.getLogoStyle(), objectFit:"contain"}} />
                                            </AnimationOnScroll>
                                    )
                                })}
                           
                           
                            
                        </Grid>
                   </Grid>
                    </Container>
                </Grid>
                <Grid container item xs={12} style={{backgroundColor: Colors.pallete1_blue, width: this.state.WindowWidth, paddingTop:  getTopPadding(this.state.WindowWidth), paddingBottom: getTopPadding(this.state.WindowWidth)}}>
                <Container>
                    <Grid container item xs={12}>

                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{color:"white", lineHeight: 1.2, marginBottom: this.state.WindowWidth <= 960 ? 30 : 60}}>
                                CONTACT
                            </Pretendard>
                        </AnimationOnScroll>

                   </Grid>
                   <Grid container item xs={12}>
                       <Grid container item xs={12} md={6} style={{paddingTop: this.state.WindowWidth <= 960 ? 0 : 100}}>
                       <Grid container item xs={12}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{color:"white",fontFamily:"Pretendard-Bold"}}>
                            LOCATION
                       </Pretendard>
                   </Grid>
                   <Grid container item xs={12} style={{color:"white",marginTop: this.state.WindowWidth <= 960 ? 10 : 20}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth}>
                       서울특별시 종로구 종로5길 13 삼공빌딩 3층
                       <br />
                       13 Jong-ro gil Jongno-gu Seoul Republic of Korea
                       </Pretendard>
                   </Grid>
                   
                   <Grid container item xs={12} style={{color:"white",marginTop: this.state.WindowWidth <= 960 ? 20 : 40}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{fontFamily:"Pretendard-Bold"}}>
                            TEL/MAIL
                       </Pretendard>
                   </Grid>
                   <Grid container item xs={12} style={{color:"white",marginTop: this.state.WindowWidth <= 960 ? 10 : 20}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth}>
                       0507-1411-6988
                       <br />
                       841prod@naver.com
                       </Pretendard>
                   </Grid>

                       </Grid>
                       <Grid container item xs={12} md={6} style={{marginTop: this.state.WindowWidth <= 960 ? 20 : undefined}}>
                           <iframe 
                        style={{border: 0, width:"100%", borderRadius: 20}} 
                        allowFullScreen={false} loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d790.5813507927423!2d126.9799071325011!3d37.570954556765166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2ebe5ceb9fb%3A0x56f11d8e68295a2c!2z7IK86rO167mM65Sp!5e0!3m2!1sko!2skr!4v1635863495519!5m2!1sko!2skr"  />
                    
                        </Grid>
                    </Grid>
                    
                    </Container>
                </Grid>
                <Footer WindowWidth={this.state.WindowWidth} onHistoryChanged={(url) => { this.props.history.push(url)}}/>
            </Grid>
        )
    }
}

export default withRouter(HomeScreen);