
export type ImageSource = {
    source : string,
    title: string,
    company: string,
    videoSrc: string,
    category : string[],
};

export const convertCategory = (category: string) => {
    switch(category) {
        case "viral": return "광고/바이럴";
        case "cg": return "CG/VFX";
        case "youtube": return "유튜브콘텐츠";
        case "nft": return "NFT";
        default: 
            return "유투브콘텐츠";
    }
}

const imageList= [
    {
        source: require("../assets/images/thumbs/abst.png"),
        title: "abst1",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/894472504",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/M4.png"),
        title: "BMW_M4",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/894475782",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/showwindow.jpg"),
        title: "showwindow",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/882055541",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/VFX2023.png"),
        title: "our office",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/876264996",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/cg_showreel.png"),
        title: "CG/VFX SHOWREEL",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/722044202",
        category: ["cg"]
    },
     {
        source: require("../assets/images/thumbs/Mclaren.png"),
        title: "Mclaren",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/864745982",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/TYPICA.jpg"),
        title: "TYPICA SEOUL HOME",
        company: "TYPICA",
        videoSrc: "https://player.vimeo.com/video/854289960",
        category: ["youtube"]
    },
    {
        source: require("../assets/images/thumbs/ABST_PLANET.jpg"),
        title: "PLANET",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/853886195",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/NERDY.png"),
        title: "BL'E'NK COLLECTION",
        company: "NERDY",
        videoSrc: "https://player.vimeo.com/video/802648356",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/LUVFUME.png"),
        title: "LUVFUME",
        company: "LUVFUME",
        videoSrc: "https://player.vimeo.com/video/780606396",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/amore.png"),
        title: "MEDIA FASADE",
        company: "AMORE PACIFIC",
        videoSrc: "https://player.vimeo.com/video/742903428",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/contentlab.jpg"),
        title: "대놓고 사내연애",
        company: "CONTENTS LAB BLUE",
        videoSrc: "https://www.youtube.com/embed/ViHrYzLoHC4",
        category: ["youtube"]
    },
    {
        source: require("../assets/images/thumbs/el.png"),
        title: "EL LENESTIGE",
        company: "Chariscell",
        videoSrc: "https://player.vimeo.com/video/721003334",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/SKLAB07.png"),
        title: "스크랩 EP07",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/TEY3mr5Irbc",
        category: ["youtube"]
    },
    {
        source: require("../assets/images/thumbs/showreel.png"),
        title: "SHOWREEL 2021-2022",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/698900997",
        category: ["cg"],
    },
    {
        source : require("../assets/images/thumbs/v6.png"),
        title: "SHOWREEL WITH UGI",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/525402302",
        category: ["cg"]
    },
    {
        source : require("../assets/images/thumbs/icamper.png"),
        title: "XCOVER 2.0",
        company: "IKAMPER",
        videoSrc:"https://player.vimeo.com/video/699637168",
        category: ["cg", "viral"],
    },
    {
        source : require("../assets/images/thumbs/v21.png"),
        title: "SHARE.IT 전자레인지",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525088349",
        category: ["cg", "viral"]
    },
    {
        source : require("../assets/images/thumbs/SKLAB06.png"),
        title: "스크랩 EP06",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/DEKRNmEROjI",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/SKLAB05.png"),
        title: "스크랩 EP05",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/uMo5GY2d12s",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/SKLAB04.png"),
        title: "스크랩 EP04",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/ycprW04pGRk",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/sklab3.png"),
        title: "스크랩 EP03",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/wW1JvLX_SBI",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/sklab2.png"),
        title: "스크랩 EP02",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/q9PordGVR_8",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/v1.png"),
        title: "스크랩 EP01",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/Gb1uhbNHB04",
        category: ["youtube"]
    },
    {
        source: require("../assets/images/thumbs/skmagic5.png"),
        title: "생활필水품이 필요해! EP05",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/Aw0qgLf3org",
        category: ["youtube"],
    },
    {
        source: require("../assets/images/thumbs/skmagic4.png"),
        title: "생활필水품이 필요해! EP04",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/sQl-Cl_GsK8",
        category: ["youtube"],
    },
    {
        source: require("../assets/images/thumbs/skmagic3.png"),
        title: "생활필水품이 필요해! EP03",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/RGTS0QZAtxM",
        category: ["youtube"],
    },
    {
        source: require("../assets/images/thumbs/skmagic2.png"),
        title: "생활필水품이 필요해! EP02",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/A8JO5TluY0A",
        category: ["youtube"],
    },
    {
        source: require("../assets/images/thumbs/skmagic1.png"),
        title: "생활필水품이 필요해! EP01",
        company: "SK Magic",
        videoSrc: "https://www.youtube.com/embed/ETiGYNp4vIg",
        category: ["youtube"],
    },
    {
        source: require("../assets/images/thumbs/ben.png"),
        title: "벚꽃이 피면 우리 그만 헤어져",
        company: "BEN",
        videoSrc: "https://www.youtube.com/embed/H8ZJBvY8ByM",
        category: ["cg"],
    },
    {
        source : require("../assets/images/thumbs/v2.png"),
        title: "아시안필름어워즈",
        company: "BIFF",
        videoSrc: "https://player.vimeo.com/video/629956718",
        category: ["cg"]
    },
    {
        source : require("../assets/images/thumbs/v3.png"),
        title: "AFA 후보작 소개/발표",
        company: "BIFF",
        videoSrc: "https://player.vimeo.com/video/629968985",
        category: ["cg"]
    },
    {
        source : require("../assets/images/thumbs/v5.jpg"),
        title: "대행사",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/561254645",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v7.png"),
        title: "BRT INTRODUCE",
        company: "서울특별시",
        videoSrc: "https://player.vimeo.com/video/588136237",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/v8.png"),
        title: "강의",
        company: "도로교통공단",
        videoSrc: "https://player.vimeo.com/video/588192910",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/v9.png"),
        title: "TOPIS",
        company: "서울특별시",
        videoSrc: "https://player.vimeo.com/video/588192910",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/v10.png"),
        title: "대전광역시",
        company: "대전광역시",
        videoSrc: "https://player.vimeo.com/video/588192910",
        category: ["youtube"]
    },
    {
        source : require("../assets/images/thumbs/v11.jpg"),
        title: "야구왕",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/561245003",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v12.png"),
        title: "농구왕",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/561248489",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v13.png"),
        title: "축구왕",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/561252520",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v14.png"),
        title: "DONUT",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/568060416",
        category: ["cg"]
    },
    {
        source : require("../assets/images/thumbs/v15.png"),
        title: "FOREST",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/565206225",
        category: ["cg"]
    },
    {
        source : require("../assets/images/thumbs/v16.png"),
        title: "RED SPACE",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/564472612",
        category: ["cg"]
    },
    {
        source: require("../assets/images/thumbs/drive_car.png"),
        title: "DRIVE CAR",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697205889?h=05d3b96963",
        category: ["nft"],
    },
    {
        source: require("../assets/images/thumbs/fortune.png"),
        title: "FORTUNE",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697205913?h=376599c0ef",
        category: ["nft"]
    },
    {
        source: require("../assets/images/thumbs/baskia.png"),
        title: "BASQUIAT",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697206520?h=cc0ad22a5e",
        category: ["nft"]
    },
    {
        source: require("../assets/images/thumbs/baskia2.png"),
        title: "BASQUIAT",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697205970?h=7787a1bdd9",
        category: ["nft"]
    },
    {
        source: require("../assets/images/thumbs/kissharing.png"),
        title: "KEITH HARRING",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697206004?h=8c2be5a8a8",
        category: ["nft"]
    },
    {
        source: require("../assets/images/thumbs/kissharing2.png"),
        title: "KEITH HARRING",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697206032?h=1b076ee509",
        category: ["nft"]
    },
    {
        source: require("../assets/images/thumbs/red_man.png"),
        title: "RED MAN",
        company: "WAVE-I",
        videoSrc: "https://player.vimeo.com/video/697205945?h=bf1096147a",
        category: ["nft"]
    },
    {
        source : require("../assets/images/thumbs/v17.png"),
        title: "구해줘, 로케",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525068538",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v18.jpg"),
        title: "로케이션의 비밀",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525068538",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v19.png"),
        title: "SHARE.IT 브랜드 이미지",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525093592",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v20.png"),
        title: "SHARE.IT 여자편",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525087878",
        category:["viral"]
    },
    {
        source : require("../assets/images/thumbs/v4.png"),
        title: "LET'S NURUN",
        company: "NUSKIN",
        videoSrc: "https://player.vimeo.com/video/601895555",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v22.png"),
        title: "SHARE.IT 호스트",
        company: "SHARE.IT",
        videoSrc: "https://player.vimeo.com/video/525088249",
        category: ["viral"]
    },
    {
        source : require("../assets/images/thumbs/v23.png"),
        title: "WELCOME",
        company: "841 PRODUCTION",
        videoSrc: "https://player.vimeo.com/video/564134297",
        category: ["cg"]
    },

];

export default imageList;