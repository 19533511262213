import React from 'react';
// import logo from './logo.svg';
import Routes from "./routes";
import './App.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
