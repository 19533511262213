import React from "react";
import Pretendard from "../../typo/Pretendard";

type HeaderTabProps = {
    windowWidth: number,
    title: string,
    isSelected: boolean,
    onClickTab: () => void,
}

type HeaderTabState = {
    hover: boolean,
}

class HeaderTab extends React.PureComponent<HeaderTabProps, HeaderTabState> {
    static defaultProps = {
        isSelected: false
    }
    constructor(props: HeaderTabProps) {
        super(props);
        this.state = {
            hover: false
        }
    }

    hoverStyle () {
        if(this.state.hover === true) return { opacity: 0.6};
        else return { opacity : 1};
    }

    render() {
        return(
            <div 
                style={{color: "white", height: 70, marginRight: 5,  paddingLeft: 20, paddingRight: 20, display:"flex", flexDirection:"column", justifyContent:"flex-end", cursor:"pointer", ...this.hoverStyle()}}
                onMouseEnter={() => {this.setState({hover: true})}}
                onMouseLeave={() => {this.setState({hover: false})}}
                onClick={() => { this.props.onClickTab()}}
            >
                <Pretendard type="button" windowWidth={this.props.windowWidth} style={{fontFamily:"Pretendard-Black", marginBottom: 5}} >
                    {this.props.title}
                </Pretendard>
                <div style={{width: "100%", height: 3, backgroundColor: this.props.isSelected === true ? "white" : "transparent"}} />
            </div>
        )
    }
}

export default HeaderTab