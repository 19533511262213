import React from "react";
import { Grid, Container } from "@mui/material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Pretendard from "../typo/Pretendard";
import Header from "../pure/layout/Header";
import { withRouter, RouteComponentProps } from "react-router-dom";
import BackgroundImage from "../assets/images/about.png";
import { getTopPadding } from "../pure/layout/LayoutStyle";
import Clients from "../pure/Clients";
import Footer from "../pure/layout/Footer";
import { url } from "inspector";
import Colors from "../pure/Colors";

type ContactScreenProps = {
} & RouteComponentProps<{}>;

type ContactScreenState = {
    WindowWidth: number;
    WindowHeight: number;
}

class ContactScreen extends React.Component<ContactScreenProps, ContactScreenState> {
    constructor(props: ContactScreenProps) {
        super(props);
        this.state = {
            WindowHeight: window.innerHeight,
            WindowWidth: window.innerWidth,
        }
    }

    setWindowResize (w: number, h: number) {
        if (this.state.WindowHeight !== h || this.state.WindowWidth !== w) {
            this.setState({WindowHeight: h, WindowWidth: w});
            return;
        };
        return;
    }

    componentDidMount() {
        
        window.scrollTo(0, 0);
        window.addEventListener("resize", () => {
            this.setWindowResize(window.innerWidth, window.innerHeight);
            return;
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.setWindowResize(window.innerWidth, window.innerHeight)}, true);
    }
    
    getVideoSize () {
        // video rate is 16:9
        const currWidth = this.state.WindowWidth;
        const idealHeight = currWidth / 16 * 9 ;
        if (this.state.WindowHeight > idealHeight) {
            const videoWidth = this.state.WindowHeight / 9 * 16;
            const videoHeight = this.state.WindowHeight;
            return { videoHeight, videoWidth };
        }
        return { videoHeight : idealHeight, videoWidth : currWidth};
    }  


 
    render() {
        
        return(
            <Grid container item xs={12}>
               <Header 
                WindowWidth={this.state.WindowWidth} 
                backgroundMode={"transparent"} 
                onHistoryChanged={(path) => {this.props.history.push(path)}}
                curr="contact"/>
                
                <Grid container item xs={12} style={{width: this.state.WindowWidth}}>
                    
                <Grid container item xs={12} style={{zIndex: 1, background:`url(${BackgroundImage})`, width:"100%"}}>
                <Container>
                        <Grid container item xs={12} style={{paddingTop: getTopPadding(this.state.WindowWidth), paddingBottom: getTopPadding(this.state.WindowHeight)}}>

                        <Grid container item xs={12}>

                        <AnimationOnScroll animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="title" style={{lineHeight: 1.2, color: "white", marginTop: 20, marginBottom: this.state.WindowWidth <= 570 ? 60 : 120}}>
                               CONTACT US
                            </Pretendard>
                        </AnimationOnScroll>
                        
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: this.state.WindowWidth > 570 ? 60 : 30}}>

                        <Grid container item xs={12}>
                       <Grid container item xs={12} md={6} style={{paddingTop: this.state.WindowWidth <= 960 ? 0 : 100}}>
                       <Grid container item xs={12}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{fontFamily:"Pretendard-Bold", color:"white"}}>
                            LOCATION
                       </Pretendard>
                   </Grid>
                   <Grid container item xs={12} style={{marginTop: this.state.WindowWidth <= 960 ? 10 : 20}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{color:"white"}}>
                       서울특별시 종로구 종로5길 13 삼공빌딩 3층
                       <br />
                       13 Jong-ro gil Jongno-gu Seoul Republic of Korea
                       </Pretendard>
                   </Grid>
                   
                   <Grid container item xs={12} style={{marginTop: this.state.WindowWidth <= 960 ? 20 : 40}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{fontFamily:"Pretendard-Bold", color:"white"}}>
                            TEL/MAIL
                       </Pretendard>
                   </Grid>
                   <Grid container item xs={12} style={{marginTop: this.state.WindowWidth <= 960 ? 10 : 20}}>
                       <Pretendard type="caption" windowWidth={this.state.WindowWidth} style={{color:"white"}}>
                       0507-1411-6988
                       <br />
                       841prod@naver.com
                       </Pretendard>
                   </Grid>

                       </Grid>
                       <Grid container item xs={12} md={6} style={{marginTop: this.state.WindowWidth <= 960 ? 20 : undefined}}>
                           <iframe 
                        style={{border: 0, width:"100%", borderRadius: 20}} 
                        allowFullScreen={false} loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d790.5813507927423!2d126.9799071325011!3d37.570954556765166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2ebe5ceb9fb%3A0x56f11d8e68295a2c!2z7IK86rO167mM65Sp!5e0!3m2!1sko!2skr!4v1635863495519!5m2!1sko!2skr"  />
                    
                        </Grid>
                    </Grid>
                            
                        </Grid>


                        </Grid>
                        
                    </Container>
                </Grid>

                  
                </Grid>
                <Footer WindowWidth={this.state.WindowWidth} onHistoryChanged={(url) => { this.props.history.push(url)}}/>
            </Grid>
        )
    }
}

export default withRouter(ContactScreen);