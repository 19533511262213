import { Typography, TypographyProps } from "@mui/material";
import React from "react";

type PretendardProps = {
    style?: React.CSSProperties,
    windowWidth: number,
    type: "Black" | "Bold" | "Medium" | "Regular" | "ExtraBold" | "title" | "body" | "caption" | "button" | "caption2" | "subTitle"
} & Omit<TypographyProps, "style">

type PretendardState = {

}

class Pretendard extends React.PureComponent<PretendardProps, PretendardState> {
    static defaultProps = {
        type: "Regular"
    }
    constructor(props: PretendardProps) {
        super(props);
        this.state= {

        }
    }

    getFontSize () {
        const { windowWidth, type} = this.props;
        if(windowWidth >= 1440) {

            if (type === "title") return 96;
            else if (type === "subTitle") return 60;
            else if (type === "caption") return 22;
            else if (type === "button") return 18;
            else if (type === "caption2") return 14;
            else return 32;

        } else if (windowWidth >= 1200) {

            if (type === "title") return 72;
            else if (type === "subTitle") return 48;
            else if (type === "caption") return 20;
            else if (type === "button") return 18;
            else if (type === "caption2") return 14;
            else return 28;


        } else if (windowWidth >= 960) {

            if (type === "title") return 56;
            else if (type === "subTitle") return 36;
            else if (type === "caption") return 18;
            else if (type === "button") return 16;
            else if (type === "caption2") return 12;
            else return 24;


        } else if (windowWidth>= 760) {

            if (type === "title") return 48;
            else if (type === "subTitle") return 28;
            else if (type === "caption") return 16;
            else if (type === "button") return 16;
            else if (type === "caption2") return 12;
            else return 20;


        } else if (windowWidth >= 570) {

            if (type === "title") return 40;
            else if (type === "subTitle") return 20;
            else if (type === "caption") return 14;
            else if (type === "button") return 12;
            else if (type === "caption2") return 10;
            else return 16;


        } else {
            if (type === "title") return 32;
            else if (type === "subTitle") return 18;
            else if (type === "caption") return 12;
            else if (type === "button") return 12;
            else if (type === "caption2") return 10;
            else return 14;
        }
    }

    getFontFamily () {
        const { type } = this.props;
        switch (type) {
            case "title": {
                return { fontFamily: "Pretendard-Black", fontSize: this.getFontSize()};
            }
            case "subTitle" : {
                return { fontFamily: "Pretendard-Bold", fontSize: this.getFontSize()};
            }
            case "body": {
                return { fontFamily: "Pretendard-Regular", fontSize: this.getFontSize()};
            }
            case "caption": {
                return { fontFamily: "Pretendard-Light", fontSize: this.getFontSize()}
            }
            case "button": {
                return { fontFamily: "Pretendard-Medium", fontSize: this.getFontSize()}
            }
            case "caption2": {
                return { fontFamily: "Pretendard-Light", fontSize: this.getFontSize()}
            }
            default : {
                return { fontFamily: `Pretendard-${type}`}
            }
        }
    }

    render() {
        return(
            <Typography {...this.props} style={{ ...this.getFontFamily(), ...this.props.style}} >
                {this.props.children}
            </Typography>
        )
    }
}

export default Pretendard;