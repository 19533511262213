import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeScreen from "../pages/home";
import AboutScreen from "../pages/about";
import WorkScreen from "../pages/works"
import ContactScreen from "../pages/contact";
import URL from "./path";

class RootRouter extends React.Component {
    render() {
        return(
            <BrowserRouter>
            <Switch>
                <Route exact path={URL.main} component={HomeScreen} />
                <Route exact path={URL.about} component={AboutScreen} />
                <Route exact path={URL.works} component={WorkScreen} />
                <Route exact path={URL.contact} component={ContactScreen} />
                <Route path={"*"} component={HomeScreen} />
            </Switch>
            </BrowserRouter>
        )
    }
}

export default RootRouter;