import { Button, Grid, IconButton, Modal } from "@mui/material";
import React from "react";
import HeaderTab from "./HeaderTab";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { AnimationOnScroll } from "react-animation-on-scroll";
import Pretendard from "../../typo/Pretendard";
import { CloseRounded } from "@mui/icons-material";
type HeaderProps = {
    WindowWidth: number,
    backgroundMode: "transparent" | "black",
    curr: "home" | "about" | "works" | "contact",
    onHistoryChanged: (path: string) => void,
}

type HeaderState = {
    openMenu: boolean,
    hover?: "home" | "works" | "about" | "contact"
}

class Header extends React.PureComponent<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            openMenu: false,
            hover: undefined
        }
    }

    getIconSize () {
        const { WindowWidth} = this.props;
        if(WindowWidth <= 570) return 32;
        else return 48
    }


    renderSmall () {
        const IconSize = this.getIconSize();
        return(
            <Grid container item style={{width: this.props.WindowWidth, height: 70, position:"fixed", zIndex: 999}}>
                <Grid container item xs={6}>
                <Button onClick={() => {this.props.onHistoryChanged("/")}} style={{width: 200, height: 70}}>
                    <img src={require("../../assets/images/logo_horizon.png")} style={{width: 120, height: 70, objectFit:"contain"}} />

                    </Button>
                </Grid>
                 <Grid container item xs={6} direction="row" justifyContent="flex-end" style={{paddingLeft: 60}}>
                    {this.state.openMenu !== true &&  <IconButton onClick={() => { this.setState({openMenu: true})}} style={{marginRight: 10}}>
                     <MenuRoundedIcon style={{color: "white", fontSize: IconSize }} />
                     </IconButton>}
                 </Grid>
                 <Modal
                    open={this.state.openMenu}
                    onClose={() => {this.setState({openMenu: false})}}
                 >
                     <div style={{width:"100%", height:"100%", backgroundColor: "#00000080", paddingTop: 10}}>
                         <Grid container item xs={12} style={{width:"100%"}}>
                         <Grid container item xs={12} justifyContent="flex-end" style={{width:"100%"}}>
                         <IconButton onClick={() => { this.setState({openMenu: false})}} style={{marginRight: 10}}>
                     <CloseRounded style={{color: "white", fontSize: IconSize }} />
                     </IconButton>
                             </Grid>
                             <Grid
                                onMouseEnter={() => { this.setState({hover: "home"})}}
                                onMouseLeave={() => {this.setState({hover: undefined})}}
                              container item xs={12} style={{width:"100%"}}>
                        <Button 
                        onClick={() => {this.props.onHistoryChanged("/")}}
                        style={{width: this.props.WindowWidth, paddingTop: 12, paddingBottom: 12, paddingLeft: 24}}>
                             <Grid container item xs={12} style={{width:'100%'}}>
                                 <Pretendard windowWidth={this.props.WindowWidth} type="subTitle" 
                                    style={{
                                        backgroundColor: this.state.hover !== "home" ? "transparent" : "white",
                                        color: this.state.hover !== "home" ? "white" : "black"
                                    }}
                                    >
                                    HOME
                                 </Pretendard>
                              </Grid>
                         </Button>
                         </Grid>
                         <Grid
                                onMouseEnter={() => { this.setState({hover: "about"})}}
                                onMouseLeave={() => {this.setState({hover: undefined})}}
                              container item xs={12} style={{width:"100%"}}>
                        <Button 
                         onClick={() => {this.props.onHistoryChanged("/about")}}
                        style={{width: this.props.WindowWidth, paddingTop: 12, paddingBottom: 12, paddingLeft: 24}}>
                             <Grid container item xs={12} style={{width:'100%'}}>
                             <Pretendard windowWidth={this.props.WindowWidth} type="subTitle" 
                                    style={{
                                        backgroundColor: this.state.hover !== "about" ? "transparent" : "white",
                                        color: this.state.hover !== "about" ? "white" : "black"
                                    }}
                                    >
                                    ABOUT
                                 </Pretendard>
                              </Grid>
                         </Button>
                         </Grid>
                         <Grid
                                onMouseEnter={() => { this.setState({hover: "works"})}}
                                onMouseLeave={() => {this.setState({hover: undefined})}}
                              container item xs={12} style={{width:"100%"}}>
                        <Button 
                         onClick={() => {this.props.onHistoryChanged("/works")}}
                        style={{width: this.props.WindowWidth, paddingTop: 12, paddingBottom: 12, paddingLeft: 24}}>
                             <Grid container item xs={12} style={{width:'100%'}}>
                             <Pretendard windowWidth={this.props.WindowWidth} type="subTitle" 
                                    style={{
                                        backgroundColor: this.state.hover !== "works" ? "transparent" : "white",
                                        color: this.state.hover !== "works" ? "white" : "black"
                                    }}
                                    >
                                    WORKS
                                 </Pretendard>
                              </Grid>
                         </Button>
                         </Grid>
                         <Grid
                                onMouseEnter={() => { this.setState({hover: "contact"})}}
                                onMouseLeave={() => {this.setState({hover: undefined})}}
                              container item xs={12} style={{width:"100%"}}>
                        <Button 
                         onClick={() => {this.props.onHistoryChanged("/contact")}}
                        style={{width: this.props.WindowWidth, paddingTop: 12, paddingBottom: 12, paddingLeft: 24}}>
                             <Grid container item xs={12} style={{width:'100%'}}>
                             <Pretendard windowWidth={this.props.WindowWidth} type="subTitle" 
                                    style={{
                                        backgroundColor: this.state.hover !== "contact" ? "transparent" : "white",
                                        color: this.state.hover !== "contact" ? "white" : "black"
                                    }}
                                    >
                                    CONTACT
                                 </Pretendard>
                              </Grid>
                         </Button>
                         </Grid>

                         <Grid
                                onMouseEnter={() => { this.setState({hover: "contact"})}}
                                onMouseLeave={() => {this.setState({hover: undefined})}}
                              container item xs={12} style={{width:"100%"}}>
                        <Button 
                         onClick={() => {
                            window.open("http://drive.google.com/uc?export=view&id=1H-e4c2zNv1clVRTe2zNmXJ-opjBz7c6d", "_blank");
                            return;
                         }}
                        style={{width: this.props.WindowWidth, paddingTop: 12, paddingBottom: 12, paddingLeft: 24}}>
                             <Grid container item xs={12} style={{width:'100%'}}>
                             <Pretendard windowWidth={this.props.WindowWidth} type="subTitle" 
                                    style={{
                                        backgroundColor: this.state.hover !== "contact" ? "transparent" : "white",
                                        color: this.state.hover !== "contact" ? "white" : "black"
                                    }}
                                    >
                                    회사소개서
                                 </Pretendard>
                              </Grid>
                         </Button>
                         </Grid>
                         
                         </Grid>
                       
                     </div>
                 </Modal>
            </Grid>
        )
    }

    renderFull() {
        const { curr } = this.props;
        return(
            <Grid container item style={{width: "100%", height: 70, position:"fixed", zIndex: 999, backgroundColor: this.props.backgroundMode === "black" ? "#00000070" : "transparent"}}>
                <Grid container item xs={4} style={{paddingLeft: 20}}>
                    <Button onClick={() => {this.props.onHistoryChanged("/")}} style={{width: 200, height: 70}}>
                    <img src={require("../../assets/images/logo_horizon.png")} style={{width: 120, height: 70, objectFit:"contain"}} />

                    </Button>
                </Grid>
                 <Grid container item xs={8} direction="row" justifyContent="flex-end" style={{paddingRight: 20}}>
                    <HeaderTab title="HOME" windowWidth={this.props.WindowWidth} isSelected={curr === "home"} onClickTab={() => {this.props.onHistoryChanged("/");}}/>
                    <HeaderTab title="ABOUT" windowWidth={this.props.WindowWidth} isSelected={curr === "about"}  onClickTab={() => {this.props.onHistoryChanged("/about");}}/>
                    <HeaderTab title="WORKS" windowWidth={this.props.WindowWidth}  isSelected={curr === "works"}onClickTab={() => {this.props.onHistoryChanged("/works");}}/>
                    <HeaderTab title="CONTACT" windowWidth={this.props.WindowWidth} isSelected={curr === "contact"} onClickTab={() => {this.props.onHistoryChanged("/contact");}}/>
                    <HeaderTab title="회사소개서" windowWidth={this.props.WindowWidth} isSelected={false} onClickTab={() => {
                        window.open("http://drive.google.com/uc?export=view&id=1H-e4c2zNv1clVRTe2zNmXJ-opjBz7c6d", "_blank");
                        return;
                    }}/>
                </Grid>
            </Grid>
        )
    }

    

    render() {
        if(this.props.WindowWidth <= 960) return this.renderSmall();
        else return this.renderFull();
    }
}

export default Header;