import React from "react";
import { Grid, Container, ImageList, Modal, IconButton } from "@mui/material";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Pretendard from "../typo/Pretendard";
import Header from "../pure/layout/Header";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Images from "../pure/PreviewImages";
import TagButton from "../pure/buttons/TagButton";
import WorkImage from "../pure/images/WorkImage";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { getTopPadding } from "../pure/layout/LayoutStyle";
import Footer from "../pure/layout/Footer";
import Colors from "../pure/Colors";
import BackgroundImage from "../assets/images/BG.png";

type WorkScreenProps = {
} & RouteComponentProps<{}>;

type WorkScreenState = {
    WindowWidth: number;
    WindowHeight: number;
    playVideo: undefined | string;
    category? : "viral" | "cg" | "youtube" | "nft"
}

class WorkScreen extends React.Component<WorkScreenProps, WorkScreenState> {
    constructor(props: WorkScreenProps) {
        super(props);
        this.state = {
            WindowHeight: window.innerHeight,
            WindowWidth: window.innerWidth,
            playVideo: undefined,
            category: undefined,
        }
    }

    setWindowResize (w: number, h: number) {
        if (this.state.WindowHeight !== h || this.state.WindowWidth !== w) {
            this.setState({WindowHeight: h, WindowWidth: w});
            return;
        };
        return;
    }

    componentDidMount() {
        
        window.scrollTo(0, 0);
        window.addEventListener("resize", () => {
            this.setWindowResize(window.innerWidth, window.innerHeight);
            return;
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { this.setWindowResize(window.innerWidth, window.innerHeight)}, true);
    }

    getVideoSize () {
        // video rate is 16:9
        const currWidth = this.state.WindowWidth;
        const idealHeight = currWidth / 16 * 9 ;
        return idealHeight;
    }   

    renderTopPadding() {
        if(this.state.WindowWidth <= 570) return 30;
        else return 100;
    }

    renderTagMargin() {
        if(this.state.WindowWidth <= 570) return 10;
        else return 40;
    }

    getImageCols () {
        if(this.state.WindowWidth <= 570) return 1;
        else if (this.state.WindowWidth <= 970) return 3;
        else return 4;
    }

    getImageHeight () {
        if(this.state.WindowWidth <= 570) return 240;
        else if (this.state.WindowWidth <= 970) return 320;
        else return 360;
    }

    getModalVideoRate () {
        if(this.state.WindowWidth <= 570) return 0.9;
        else if (this.state.WindowWidth <= 970) return 0.75;
        else return 0.6;
    }

    getCloseButtonStyle () {
        if(this.state.WindowWidth <= 570) return {height: 40, width: 40, top: -40, right: 0}
        else if (this.state.WindowWidth <= 970) return {height: 60, width: 60, top: 0, right: -60}
        else return {height: 80, width: 80, top: 0, right: -80}
    }

    getCloseIconSize () {
        if(this.state.WindowWidth <= 570) return 30;
        else if (this.state.WindowWidth <= 970) return 50;
        else return 70;
    }
    
    filteredImages () {
        if(this.state.category === undefined) return Images;
        const targetCategory = this.state.category;
        const filtered = Images.filter((image) => {
            if(image.category.includes(targetCategory)) return true;
            else return false;
        });
        return filtered;
    }
 
    render() {
        const renderImages = this.filteredImages();
        const background = this.state.WindowWidth <= 570 ? {backgroundColor: Colors.pallete1_blue}:{ background: `url(${BackgroundImage})`};
        const titleColor = this.state.WindowWidth <= 570 ? Colors.pallete1_yellow : "white"
        return(
            <Grid container item xs={12} style={{ ...background, minHeight: this.state.WindowHeight}}>
               <Header 
                WindowWidth={this.state.WindowWidth} 
                backgroundMode={"transparent"} 
                onHistoryChanged={(path) => {this.props.history.push(path)}}
                curr="works"/>
                
                <Grid container item xs={12} style={{width: this.state.WindowWidth, paddingTop: getTopPadding(this.state.WindowWidth)}}>
                    <Container>
                        <Grid container item xs={12}>

                        <Grid container item xs={12}>
                        <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{lineHeight: 1.2, color: titleColor}}>
                                WORKS
                            </Pretendard>
                        {/* <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInUp">
                            <Pretendard windowWidth={this.state.WindowWidth} type="subTitle" style={{lineHeight: 1.2, color: "white"}}>
                                WORKS
                            </Pretendard>
                        </AnimationOnScroll> */}
                        
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: this.renderTagMargin()}}>

                            {this.state.WindowWidth > 960 && <AnimationOnScroll animateOnce={true}  animateIn="animate__slideInUp">
                            <TagButton onClick={() => {this.setState({category: undefined})}} isSelected={this.state.category === undefined} title="#ALL" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "viral"})}}  isSelected={this.state.category === "viral"}  title="#광고/바이럴" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "cg"})}}  isSelected={this.state.category === "cg"}  title="#CG/VFX" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "youtube"})}}  isSelected={this.state.category === "youtube"}  title="#유튜브콘텐츠" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "nft"})}} isSelected={this.state.category === "nft"}   title="#NFT" WindowWidth={this.state.WindowWidth} />
                            </AnimationOnScroll>}

                            {this.state.WindowWidth <= 960 && <>
                            <TagButton onClick={() => {this.setState({category: undefined})}} isSelected={this.state.category === undefined} title="#ALL" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "viral"})}}  isSelected={this.state.category === "viral"}  title="#광고/바이럴" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "cg"})}}  isSelected={this.state.category === "cg"}  title="#CG/VFX" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "youtube"})}}  isSelected={this.state.category === "youtube"}  title="#유튜브콘텐츠" WindowWidth={this.state.WindowWidth} />
                            <TagButton onClick={() => {this.setState({category: "nft"})}} isSelected={this.state.category === "nft"}   title="#NFT" WindowWidth={this.state.WindowWidth} />
                            </>}
                            
                        </Grid>


                        </Grid>
                        
                    </Container>
                </Grid>
                <Grid container item xs={12} style={{width: this.state.WindowWidth, paddingTop: this.state.WindowWidth < 570 ? 20 : 40}}>
                    <ImageList
                        sx={{width: "100%"}}
                        variant="quilted"
                        cols={this.getImageCols()}
                        rowHeight={this.getImageHeight()}
                        gap={0}
                    >
                        {renderImages.map((image) => {
                            return(
                               <WorkImage 
                               key={image.source}
                                image={image} 
                                WindowWidth={this.state.WindowWidth}
                                onClick={(img) => { this.setState({playVideo: img.videoSrc})}}
                                />
                            )
                        })}
                    </ImageList>
                </Grid>
                <Modal
                    open={this.state.playVideo !== undefined}
                    onClose={() => { this.setState({playVideo: undefined})}}
                    style={{display:"flex", alignItems:"center", justifyContent:"center", outline: 'none'}}
                >
                    <Grid container item style={{width:`${this.getModalVideoRate() * 100}%`, outline:"none", backgroundColor: "black", height: this.getVideoSize() * this.getModalVideoRate(), position:"relative"}}>
                    {this.state.playVideo !== undefined && <iframe id="myvideo" title="vimeo-player" src={this.state.playVideo} frameBorder={0} allowFullScreen style={{width:"100%", height: "100%"}} />}
                    <IconButton 
                        onClick={() => {this.setState({playVideo: undefined})}}
                        style={{position:"absolute", ...this.getCloseButtonStyle()}}>
                        <CloseRounded style={{fontSize: this.getCloseIconSize(), color: "white"}} />
                    </IconButton>
                    </Grid>
                    
                </Modal>
                <Footer WindowWidth={this.state.WindowWidth} onHistoryChanged={(url) => { this.props.history.push(url)}}/>
            </Grid>
        )
    }
}

export default withRouter(WorkScreen);