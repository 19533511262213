import React from "react";
import { ImageSource } from "../PreviewImages";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Pretendard from "../../typo/Pretendard";
import Colors from "../Colors";

type SlideImageProps = {
    image: ImageSource,
    windowWidth: number,
    onClick: (image: ImageSource) => void,
}

type SlideImageState = {
    hover: boolean
}

class SlideImage extends React.PureComponent<SlideImageProps, SlideImageState> {
    static defaultProps = {
        windowWidth: window.innerWidth,
        onClick: () => {}
    }
    constructor(props: SlideImageProps) {
        super(props);
        this.state= {
            hover: false,
        }
    }


    render() {
        const { image } = this.props;
        return(
            <div 
                onClick={() => {this.props.onClick(image)}}
                onMouseEnter={() => {this.setState({hover: true})}} 
                onMouseLeave={() => {this.setState({hover: false})}}
                style={{ position:"relative", cursor:"pointer", backgroundColor:Colors.pallete1_blue, overflow:"hidden" }}>
          <div style={{}}>
            <img src={image.source} style={{width:"100%", objectFit:"contain", opacity: this.state.hover === true ? 0.6 : 1, backgroundColor: Colors.pallete1_blue, transform: this.state.hover === true ? "scale(1.2)" : undefined,
                                        transition: ".6s"}} />
           
            <div style={{width: "100%", height: "100%", position:"absolute", zIndex: 10, left: 0, top: 0, display:"flex", alignItems:"flex-start", paddingTop: 20, paddingLeft: 20}}>
                      <AnimationOnScroll duration={0.5} animateIn="animate__zoomIn">
                      <Pretendard type="caption" windowWidth={this.props.windowWidth} style={{color:"white", fontFamily:"Pretendard-Bold"}}>
                        {image.title}
                      </Pretendard>
                      <Pretendard type="caption2" windowWidth={this.props.windowWidth} style={{ color:"white", fontFamily:"Pretendard-Bold"}}>
                        {image.company}
                      </Pretendard>
                      </AnimationOnScroll>
                    </div>
                    
            {this.state.hover === true && (
                    <div style={{width: "100%", height: "100%", position:"absolute", zIndex: 10, left: 0, top: 0, display:"flex", alignItems:"flex-start", paddingTop: 20, paddingLeft: 20}}>
                      <AnimationOnScroll duration={0.5} animateIn="animate__zoomIn">
                      
                      </AnimationOnScroll>
                    </div>)}
          </div>
         
        </div>
        )
    }
}

export default SlideImage;